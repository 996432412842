<template>
<div>
  <modal-novo-painel></modal-novo-painel>
  <svg v-if="!backgroundImage" class="wave wave01" width="555" height="460" viewBox="0 0 555 460" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.0823674 388.522C-53.6566 359.613 -85.103 321.972 -84.4282 283.358C-84.2239 244.857 -51.4279 205.268 -46.2004 164.885C-40.579 124.07 -62.1323 82.0283 -47.2524 38.3134C-32.4491 -5.72038 19.1814 -51.8593 80.6096 -69.6588C142.432 -87.8903 213.975 -78.1013 277.486 -60.3104C340.921 -42.8383 395.458 -16.8193 442.183 20.1846C489.454 57.3944 528.519 106.021 477.773 133.059C426.951 159.777 286.395 165.226 225.812 215.54C165.23 265.854 184.698 361.353 156.531 399.492C128.288 437.311 53.2741 417.226 0.0823674 388.522Z" fill="#00E4A0" fill-opacity="0.05"/>
  </svg>
  <svg v-if="!backgroundImage" class="wave wave02" width="502" height="370" viewBox="0 0 502 370" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M553.488 71.8322C607.748 99.7511 639.879 136.808 639.913 175.428C640.415 213.927 608.35 254.11 603.864 294.582C598.993 335.494 621.313 377.133 607.238 421.114C593.244 465.412 542.469 512.49 481.377 531.413C419.9 550.775 348.189 542.3 284.362 525.677C220.618 509.371 165.613 484.357 118.217 448.216C70.2711 411.88 30.3212 363.978 80.5625 336.014C130.886 308.368 271.319 300.342 330.968 248.925C390.618 197.508 369.402 102.382 396.864 63.7336C424.409 25.4024 499.779 44.109 553.488 71.8322Z" fill="#00E4A0" fill-opacity="0.05"/>
  </svg>
  <div class="container h-100 align-items-center animationOpacity" v-if="selectPainel === false">
    <div class="row justify-content-center mt-5" v-if="!existParams">
      <svg @click="toLogin" style="cursor:pointer" width="28" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.00004 8L8.00004 1M26.2961 8L1.00004 8L26.2961 8ZM1.00004 8L8.00004 15L1.00004 8Z" stroke="#B5B9C5" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <div class="login" :class="{'col-5 mt-5':!isMobile, 'col-10':isMobile}">
        <div class="headerLogin" v-if="logo === null">
          <img src="@/assets/icons/logo-fake.svg" />
        </div>
        <div class="headerLogin" v-else>
          <img :src="logo" />
        </div>
        <div class="headerLogin mt-3 mb-3" v-if="tokenNewPassword === true">
          <div class="title">{{ $t('reset_password.bem_vindo') }}</div>
        </div>
        <div class="headerLogin mt-3 mb-3" v-else>
          <div class="title" v-if="resetPassord === true">{{ $t('reset_password.header_login') }}</div>
          <div class="title" v-if="resetPassord === false">{{ $t('reset_password.header_login2') }}</div>
        </div>
        <form @submit.prevent="onSubmit" novalidate class="formLogin" v-if="resetPassord === true">
          <div class="form-group">
            <b-form-group
              :label="$t('reset_password.label_password')"
              label-for="name"
              class="labelxx"
            >
              <input
                type="password"
                class="form-control"
                v-model="password"
                name="password"
                v-validate="'required'"
                :placeholder="$t('reset_password.input_password')"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="!errors.has('password')">
              {{ $t('reset_password.input_password_error') }}
            </b-form-invalid-feedback>
          </div>

          <div class="form-group">
            <b-form-group
              :label="$t('reset_password.input_password2')"
              label-for="name"
              class="labelxx"
            >
              <input
                type="password"
                class="form-control"
                v-model="password2"
                name="password2"
                v-validate="'required'"
                :placeholder="$t('reset_password.input_password2')"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="!errors.has('password2')">
              {{ $t('reset_password.input_password_error2') }}
            </b-form-invalid-feedback>
          </div>

          <div class="btn-login" v-if="!isMobile">
            <a v-if="resetPassord === true" class="recuperar-senha" @click="reenviarEmailRedefinicao">
              {{ $t('reset_password.btn') }}
            </a>
            <a v-if="resetPassord === false" class="recuperar-senha" @click="resetPassord = true">
              {{ $t('reset_password.btn2') }}
            </a>
            <div></div>
            <Captcha ref="Captcha" @changeVerify="changeVerify">
              <button type="submit" class="btn-Acesso">{{ $t('reset_password.btn3') }}</button>
            </Captcha>

          </div>
          <div v-if="isMobile">
            <Captcha ref="Captcha" @changeVerify="changeVerify">
              <button type="submit" class="btn-Acesso btnToMobile">{{ $t('reset_password.btn3') }}</button>
            </Captcha>
          </div>
        </form>
        <form v-if="resetPassord === false" novalidate class="formLogin" @submit.prevent="onSubmit">
          <div class="form-group">
            <b-form-group
              :label="$t('reset_password.form_label_email')"
              label-for="name"
              class="labelxx"
            >
              <input
                type="text"
                class="form-control"
                v-model="email"
                name="email"
                v-validate="'required|email'"
                :placeholder="$t('reset_password.form_input_email')"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="!errors.has('email')">
             {{ $t('login.feedback_menssage_email')}}
            </b-form-invalid-feedback>
          </div>

          <div class="btn-login" v-if="!isMobile">
            <a v-if="resetPassord === true" class="recuperar-senha" @click="reenviarEmailRedefinicao">
              {{ $t('reset_password.btn4') }}
            </a>
            <a v-if="resetPassord === false" class="recuperar-senha" @click="resetPassord = true">
              {{ $t('reset_password.btn5') }}
            </a>
            <div></div>
            <button type="submit" class="btn-Acesso">{{ $t('reset_password.btn4') }}</button>
          </div>
          <div v-if="isMobile">
            <button type="submit" class="btn-Acesso btnToMobile">{{ $t('reset_password.btn6') }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <FooterBase type="home"/> 
</div>
</template>

<script>
import Cookies from 'js-cookie';
import axios from "axios";
import ModalNovoPainel from '@/components/ModalNovoPainel.vue';
import notify from "@/services/libs/notificacao";
import FooterBase from '@/components/FooterBase.vue';
import Captcha from '@/components/security/Captcha.vue';

import SiteService from "@/services/resources/SiteService";

const serviceSite = SiteService.build();

export default {
  components:{
    ModalNovoPainel,
    FooterBase,
    Captcha
  },
  data() {
    return {
      errorCaptch: false,
      captch:false,
      submit: false,
      error: false,
      email: '',
      password: null,
      password2: null,
      blogs: [],
      selectPainel:false,
      Painels:'',
      client:{
        width: 0
      },
      logo:null,
      color:null,
      existParams:false,
      backgroundImage:false,
      tokenURL:null,
      resetPassord: false,
      tokenNewPassword: false,
      painelName:'',
      emailParam:'',
      captcha:''
    }
  },
  computed: {
    isMobile() {
      return this.client.width <= 576;
    },
  },
  created(){
    window.addEventListener('resize', this.handleResize)
      this.handleResize()
  },
  mounted(){
    this.getParam()
    this.getLogobyDomain()
    this.$root.$emit('loadTheme')
  },
  methods: {
    changeVerify(e) {
      this.captcha = e
      this.onSubmit('get')
    },
    getLogobyDomain() {
      serviceSite
        .read('/login ')
        .then(resp => {
          const d = document;
          window.document.title = resp.site_name+' - Greenn.Club'
          this.painelName = resp.site_name
          if(resp.meta.logomarca !== null) {
            this.logo = resp.meta.logomarca
          } else {
            this.logo = resp.meta.logo;
          }
          if(resp.meta.logo !== null) {
            var link = document.querySelector("link[rel~='icon']")
            if (!link) {
                link = document.createElement('link')
                link.rel = 'icon'
                document.getElementsByTagName('head')[0].appendChild(link)
            }
            link.href = resp.meta.logo
          }
          if(resp.meta.theme_fixed_painel === 'on') {
            var color = resp.meta.theme_fixed_painel_color
            d.documentElement.setAttribute('data-theme', color)
          }
          if(resp.meta.main_color !== null) {
            this.color = resp.meta.main_color
            d.documentElement.setAttribute('data-color', this.color)
            document.documentElement.style.setProperty('--maincolor', this.color)
            document.documentElement.style.setProperty('--maincolorn', this.$func.convertColor(this.color, 210))
            document.documentElement.style.setProperty('--maincolortrans', this.color+'0f')
          }
          if(resp.meta.login_background_image) {
            this.backgroundImage = true;
            var background = resp.meta.login_background_image.cdn_url
            document.body.style.backgroundImage = "url("+background+")"
            document.body.style.backgroundPosition = "center center"
            document.body.style.backgroundRepeat = "no-repeat"
          }
        })
        .catch(err => {
          console.error(err)
          // if(err.response.status === 404){
          //   // this.$router.push('/config-domain')
          // }
        })
    },
    getParam(){
      var queryString = window.location.search
      var tath = this
      if(queryString !== "") {
          const urlParams = new URLSearchParams(queryString)
          const params = urlParams.get("tokenNewPassword")
          const params2 = urlParams.get("tokenEditPassword")
          const params3 = urlParams.get("userEmail")
          const params4 = urlParams.get("isValid")
          this.emailParam = params3
          tath.resetPassord = true
          if(params4 === 'false') {
            notify('erro', tath.$t('reset_password.get_param_error'))
          }
          if(params2 === 'resetpassword') {
            tath.reenviarEmailRedefinicao()
          }
          if(params === "" || params === null || params === undefined) {
            tath.tokenNewPassword = false
            tath.tokenURL = params2
          } else if(params2 === "" || params2 === null || params2 === undefined) {
            tath.tokenNewPassword = true
            tath.tokenURL = params
          }
      } else {
        tath.toLogin()
      }
    },
    toLogin(){
      if (process.env.VUE_APP_TYPE === 'DEV') {
        window.location.href = "http://" + window.location.host
      } else {
        window.location.href = "https://" + window.location.host
      }
    },
    reenviarEmailRedefinicao(){
      this.resetPassord = false
    },
    handleResize() {
        this.client.width = window.innerWidth
    },
    toLoginUser(resp){
      var sites = resp.sites
      Cookies.set('memberAuth', resp.Auth, { expires: 365 })
      // Cookies.set('blogsClub', resp.sites, { expires: 365 })
      if (sites.length === 0) {
        this.toLogin();
      } else {
        this.$store.dispatch("blogSelected", sites[0])
      }
    },
    onSubmit(get) {
      this.submit = true
      var that = this

      if(get !== 'get' && this.resetPassord === true) {
        this.$refs.Captcha.setCaptcha()
        return
      }

      var data = ''
      var url = ''
      if(this.resetPassord === false) {
        data = { email: this.email }
        url = process.env.VUE_APP_API_HOST + `/member/password-recovery`
      } else {
        data = {
          email: this.emailParam,
          password: this.password,
          token: this.tokenURL,
          captcha:this.captcha
        }
        url = process.env.VUE_APP_API_HOST + '/member/password-reset'
        if(this.password !== this.password2) {
          notify('erro', this.$t('reset_password.get_param_error2'))
          return false
        }
      }

      axios.post(url, data)
        .then(function(resp) {
          if(that.resetPassord === true){
              notify('sucesso', that.$t('reset_password.get_param_msg'))
              setTimeout(() => {
                that.toLoginUser(resp.data)
              }, 1000)
          } else {
            notify('sucesso', resp.data)
          }
        }).catch(err => {
          that.error = true
          console.error(err)
          this.$refs.Captcha.resetCaptcha()
          if(err.response) {
            var error = err.response.data
            var msg = ''
            for (var indice in error) {
              msg += error[indice][0] + "<br>"
            }
            if (msg !== '') notify('erro', msg)
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.heightList {
    height: 30vh;
    overflow: auto;
}

.toCenter{
      display: flex;
    justify-content: center;
}
.spaceSelectPainel{
  background: var(--backgroundcolor);
  border-radius: 5px;
  padding: 20px 5px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
  .spacePainels {
      border: 0.5px solid #EDEDF0;
      border-radius: 3px;
      padding: 15px;
      transition: 0.3s;
  }
  .spacePainels:hover {
      border: 0.5px solid #EDEDF0;
      padding: 15px;
  }
}

.spaceSelectPainelMobile{
  width: 100%;
  background: var(--backgroundcolor);
  // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 30px;
}

.lineDiv{
  height: 0.5px;
  background: #EDEDF0;
  width: 100%;
  margin-bottom: 25px;
  margin-top: 25px;
}
.flexData{
  display: flex;
  margin-bottom: 20px;
  cursor: pointer;
  .spaceImg{
    width: 60px;
    height: 60px;
    overflow: hidden;
    img{
        width: 100%;
    }
  }
  .title{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--fontcolor);
    margin-left: 20px;
    line-height: 40px;
  }
  .descricao{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #5d5d5d;
    margin-left: 20px;
  }
}
.btn-Acesso {
  background: var(--maincolor);
  border-radius: 5px;
  padding: 14px 28px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  border: none;
  outline: none;
  transition: 0.3s;
}
.btn-Acesso:hover {
  transform: scale(1.05);
}
.formLogin {
  .btnToMobile{
    width: 100%;
    margin-bottom: 15px;
  }
  input {
    border: solid 1px var(--bordercolor);;
    box-sizing: border-box;
    border-radius: 5px;
    height: 60px;
    font-size: 14px;
    padding: 20px;
  }
  input:hover,
  input:focus {
    border: 1px solid var(--maincolor);
    box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
    background: rgba(0, 228, 160, 0.05);
    color: #333;
    font-weight: normal;
  }
  input:focus {
    font-weight: 600;
  }
  .labelxx {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
  }
}
.headerLogin {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  .title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: var(--fontcolor);
  }
}
.login {
  // background: #fff;
  border-radius: 5px;
  padding: 10px;
}

.h-100 {
  height: 100vh;
}
.wave {
  position: fixed;
  z-index: -1;
  path{
    fill: var(--maincolor) !important;
  }
}
.wave01 {
  top: 0;
  left: 0;
}
.wave02 {
  bottom: 0;
  right: 0;
}
.btn-login {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recuperar-senha {
  color: var(--maincolor);
  font-weight: 600;
  cursor: pointer;
}
.recuperar-senha:hover {
  color: var(--maincolor);
}
</style>